import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { VscMenu } from 'react-icons/vsc'
import Buger from '../assets/burger_icon_2.svg'
import BugerClose from '../assets/buger-icon-close.svg'
import Logo from '../assets/logo-dark.svg'
import SearchForm from '../components/search-form'
import { GrInstagram } from 'react-icons/gr'
import SiteMetaHook from '../hooks/site-meta-hook'

export default () => {
  const siteMeta = SiteMetaHook()
  const navRef = useRef(null)
  const [navHeight, setNavHeight] = useState(0)
  const [fixed, setFixed] = useState(false)
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [hideNav, setHideNav] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const scrollHanlder = () => {
    if (navRef.current === null) return
    const st = window.pageYOffset
    if (window.pageYOffset > navHeight) {
      setFixed(true)

      if (st > lastScrollTop) {
        setHideNav(true)
        setIsActive(false)
        // downscroll code fn here
      } else {
        setHideNav(false)
        // upscroll code
      }
    } else {
      setFixed(false)
      setHideNav(false)
    }

    setLastScrollTop(st <= 0 ? 0 : st) // For Mobile or negative scrolling
  }

  const resizeHanlder = () => {
    if (navRef.current !== null) {
      setNavHeight(navRef.current.offsetHeight)
    }
  }

  useEffect(() => {
    resizeHanlder()
    // window.addEventListener("resize",resizeHanlder)
    // return () => {window.removeEventListener("resize",resizeHanlder)}
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', scrollHanlder)
    return () => {
      window.removeEventListener('scroll', scrollHanlder)
    }
  })

  return (
    <>
      <nav
        role="navigation"
        ref={navRef}
        className={`w-screen bg-white transition-all duration-500 z-40 flex item-center justify-between px-6 border-gray-ee border-b md:px-20 fixed ${
          fixed ? 'py-4 md:py-6' : 'py-6 md:py-10'
        }`}
        style={{
          top: hideNav ? `-${navHeight}px` : '0px',
        }}
      >
        <div>
          <Link to="/">
            <Logo
              className={`md:h-auto transition-all duration-500 ${
                fixed ? 'md:w-24' : 'md:w-32 '
              }`}
            />
          </Link>
        </div>
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            setIsActive(!isActive)
          }}
        >
          {isActive ? <BugerClose /> : <Buger />}
        </div>
        <div
          className={`absolute z-30 bg-white px-6 py-8 top-0 right-0 w-screen h-screen bg-black transition-all duration-300 md:px-20  lg:px-14 lg:w-auto ${
            isActive
              ? 'opacity-100 pointer-events-auto'
              : 'opacity-0 pointer-events-none'
          }`}
          style={{
            top: 'calc(100% + 1px)',
          }}
        >
          <ul className="text-gray-99 text-17-25 grid grid-cols-1 gap-6 font-medium">
            <li className="text-17 hover:text-gray-600">
              <Link
                to="/stories"
                getProps={({ isCurrent }) => {
                  // the object returned here is passed to the
                  // anchor element's props
                  const cName = isCurrent ? 'text-gray-600' : ''
                  return {
                    className: cName
                  };
                }}
              >STORIES</Link>
            </li>
            {/*<li className="text-xl">*/}
            {/*  <Link to="/register">REGISTER</Link>*/}
            {/*</li>*/}
            <li className="text-17 hover:text-gray-600 hidden">
              <Link
                to="/body-of-work/"
                getProps={({ isCurrent }) => {
                  // the object returned here is passed to the
                  // anchor element's props
                  const cName = isCurrent ? 'text-gray-600' : ''
                  return {
                    className: cName
                  };
                }}
              >BODY OF WORK</Link>
            </li>
            {/*<li className="text-17">*/}
            {/*  <Link to="/stories/">SHOP</Link>*/}
            {/*</li>*/}
            <li className="text-17 hover:text-gray-600 hidden">
              <Link
                to="/booking/"
                getProps={({ isCurrent }) => {
                  // the object returned here is passed to the
                  // anchor element's props
                  const cName = isCurrent ? 'text-gray-600' : ''
                  return {
                    className: cName
                  };
                }}
              >ONLINE BOOKING</Link>
            </li>
            <li>
              <SearchForm />
            </li>
            <li>
              <a href={siteMeta.site.siteMetadata.igLink} target="_blank">
                <GrInstagram className="w-6 h-auto text-gray-99" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
      {/*<div style={{*/}
      {/*  height:`${navHeight}px`*/}
      {/*}}/>*/}
      <div style={{ height: 62 }} className="md:hidden"></div>
      <div style={{ height: 110 }} className="hidden md:block"></div>
      <div
        onClick={() => {
          setIsActive(false)
        }}
        className={`fixed inset-0 z-20 transition-opacity duration-500 bg-gray-900 ${
          isActive
            ? 'opacity-70 pointer-events-auto'
            : 'opacity-0 pointer-events-none'
        }`}
      />
    </>
  )
}
