import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const SiteMetaHook = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          igLink
        }
      }
    }
  `)
  return data
}

export default SiteMetaHook
