import React from 'react'
import { Link } from 'gatsby'
import { GrInstagram } from 'react-icons/gr'
import { FaFacebookF } from 'react-icons/fa'
import Logo from '../assets/logo-light.svg'
import UpLogo from '../assets/up.svg'
import SiteMetaHook from '../hooks/site-meta-hook'

const CopyrightTxt = () => {
  return (
    <>
      © Ryzen Co., Ltd. All rights reserved.
      <br />
      Site by Mounts Studio
    </>
  )
}

export default () => {
  const siteMeta = SiteMetaHook()
  return (
    <footer className="py-8 px-6 text-white bg-gray-1a md:px-20 md:pt-12 md:pb-10 relative">
      <div
        className="border border-white w-8 h-8 cursor-pointer flex justify-center items-center absolute top-0 right-0 mr-6 mt-5 md:h-20 md:w-20 md:mr-20 md:mt-12"
        onClick={() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        }}
      >
        <UpLogo className="md:w-16 md:h-auto md:stroke-p5" />
      </div>
      <div className="flex items-baseline justify-between md:justify-start md:items-stretch">
        <div className="md:w-1/3 md:border-r md:border-white md:flex md:items-center">
          <div>
            <div>
              <Logo className="w-18 h-auto" />
            </div>
            <ul className="text-14 tracking-widest mt-8 invisible">
              <li className="inline-block border-r border-white pr-3">
                <Link to="/about/">關於我們</Link>
              </li>
              <li className="inline-block pl-3 invisible">
                <Link to="/careers/">工作機會</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="md:w-1/3 md:flex-initial">
          <div>
            <ul className="flex justify-end md:justify-center">
              {/*<li className="leading-zero border border-white w-8 h-8 rounded-full flex items-center justify-center mr-6 md:mr-5">*/}
              {/*  <a href="#" target="_blank">*/}
              {/*    <FaFacebookF />*/}
              {/*  </a>*/}
              {/*</li>*/}
              <li className="leading-zero border border-white w-8 h-8 rounded-full flex items-center justify-center">
                <a href={siteMeta.site.siteMetadata.igLink} target="_blank">
                  <GrInstagram />
                </a>
              </li>
            </ul>
            <div className="copyright w-64 text-center text-14-25 mt-5 mx-auto hidden md:block">
              <CopyrightTxt />
            </div>
          </div>
        </div>
      </div>
      <div className="copyright text-center pt-6 mt-8 border-t border-gray-99 text-12-17 md:hidden">
        <CopyrightTxt />
      </div>
    </footer>
  )
}
