import React from 'react'
import { Link } from 'gatsby'
import Navigation from './navigation'
import Footer from './footer'
import '../style/style.css'
import SEO from '../components/SEO'
// import LoginToView from "../components/loginToView"
import SiteMetaHook from '../hooks/site-meta-hook'

const Template = ({ children }) => {
  const siteMeta = SiteMetaHook()
  const getChildren = () => {
    if (typeof children === 'function') {
      return children({
        siteMeta: siteMeta.site.siteMetadata,
      })
    }

    // return React.Children.map(this.props.children, child =>
    //   React.cloneElement(child, {
    //     ...this.getChildProps(),
    //     isVisible: this.state.isVisible
    //   })
    // );
    return children
  }

  return (
    <div className="wrap">
      <SEO />
      <Navigation />
      {getChildren(siteMeta)}
      <Footer />
    </div>
  )
}

export default Template
