import React, { useState } from 'react'
import { Link } from 'gatsby'
import { AiOutlineSearch } from 'react-icons/ai'

export default () => {
  const [query, setQuery] = useState('')
  const [isFocus, SetIsFocus] = useState(false)
  return (
    <div
      className={`transition-all duration-300 relative ${
        isFocus ? 'w-4/5' : 'w-3/5'
      }`}
    >
      <input
        placeholder={`${isFocus ? '' : 'SEARCH'}`}
        onFocus={() => {
          SetIsFocus(true)
        }}
        onBlur={() => {
          SetIsFocus(false)
        }}
        className="text-sm border-b text-gray-99 border-gray-99 outline-none focus:outline-none w-full pr-4"
        type="text"
        value={query}
        required
        onChange={(e) => {
          setQuery(e.target.value)
        }}
      />
      <div className="absolute right-0 top-0 flex items-center h-full justify-center">
        <Link to={`/search/${query}`}>
          <AiOutlineSearch className="text-sm w-4" />
        </Link>
      </div>
    </div>
  )
}
